import Vue from "vue";

class I18nFormatter {
    replacePlaceholders(message, variables) {
        for (let v in variables) {
            message = message.replace("{" + v + "}", variables[v]);
        }
        return message;
    }
}

export default {
    install() {
        Vue.I18nFormatter = new I18nFormatter();
        Vue.prototype.$I18nFormatter = new I18nFormatter();
    }
};