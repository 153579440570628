import Vue from "vue";

import templates_de from "../../testdata/templates_de.json"
import templates_en from "../../testdata/templates_en.json"
import { shuffle } from "../helpers/shuffle"

const getTemplates = () => {
    return new Promise(
        (resolve, reject) => {
            const language = localStorage.getItem('Language')
            if (language === 'de' || language === 'en') {
                resolve(language === 'de' ? shuffle(templates_de) : shuffle(templates_en))
            } else {
                reject('unknown language')
            }
        }
    )
}

/** VUEX module for quiz app **/
export default {
    namespaced: true,
    // -----------------------------------------------------------------
    state: {
        templates: [],
        currentTemplateIndex: 0
    },
    actions: {
        // getTemplates: async (context) => {
        //     let api = context.rootState.settings.ExternalAPI
        //     if (!api) {
        //         return
        //     }
        //     return await Vue.axios.get(api);
        // },

        publishResult: async (context, payload) => {
            const hook = localStorage.getItem('FinishHook')
            const lang = localStorage.getItem('Language')
            var bodyFormData = new FormData();
            bodyFormData.append('q', payload);

            if (!hook || !lang) {
                return
            }
            return await Vue.axios.post('https://layer8.app/' + lang + '/' + hook, bodyFormData);
        },

        async fetchTemplates(context) {
            const templates = await getTemplates()
            return new Promise((resolve, reject) => {
                if (templates) {
                    context.commit('setTemplates', templates)
                    resolve()
                } else {
                    reject('something went wrong fetching templates')
                }
            })
        },

        setCurrentTemplateIndex(context, i) {
            context.commit('setCurrentTemplateIndex', i)
        }
    },
    getters: {
        templates: state => state.templates ? state.templates : [],
        currentTemplateIndex: state => state.currentTemplateIndex
    },
    mutations: {
        setTemplates: (state, data) => { state.templates = data; },
        setCurrentTemplateIndex: (state, data) => { state.currentTemplateIndex = data }
    }
};
