import Vue from "vue";

export default {
    namespaced: true,
    // -----------------------------------------------------------------
    state: {
        Logo: undefined,
        Genders: undefined,
        FinishHook: undefined,
        Language: undefined
    },
    // -----------------------------------------------------------------
    getters: {
        //App settings
        Genders: state => state.Genders ? state.Genders : [],
        FinishHook: state => state.FinishHook,
        Language: state => state.Language,

        logoImage: state => state?.Logo?.image,
        logoUrl: state => state?.Logo?.url
    },
    // -----------------------------------------------------------------
    mutations: {
        setGenders: (state, data) => { state.Genders = data; },
        setFinishHook: (state, data) => { state.FinishHook = data; },
        setLanguage: (state, data) => { state.Language = data },
        setLogo: (state, data) => { state.Logo = data }
    },
    // -----------------------------------------------------------------
    actions: {
        settings: async (context) => {
            try {
                const res = await Vue.axios.get(`/settings.json`)
                const data = res.data
                Object.keys(context.state).forEach(el => {
                    if (!(el in data)) {
                        console.log(el, data)
                        throw new Error(el + ' not defined in settings.json')
                    }
                    context.commit("set" + el, data[el]);
                })
            } catch (e) {
                alert(e.message)
            }
        }
    }
};
