import Vue from "vue"
import ei18n from "vue-ei18n"

function loadLocaleMessages() {
    const locales = require.context(
        "../locales",
        true,
        /[A-Za-z0-9-_,\s]+\.json$/i
    )
    const messages = {}
    locales.keys().forEach((key) => {
        const matched = key.match(/([A-Za-z0-9-_]+)\./i)
        if (matched && matched.length > 1) {
            const locale = matched[1]
            messages[locale] = locales(key)
        }
    })
    return messages
}

const locale = process.env.VUE_APP_I18N_LOCALE || "de"

Vue.use(ei18n, {
    locale: locale,
    messages: loadLocaleMessages(),
})
