<template>
    <button
        :class="['base-button', size, variant, buttonClass]"
        @click.stop="onClick"
        :type="type"
    >
        <base-icon :icon="icon"></base-icon>
        <slot></slot>
    </button>
</template>
<script>
export default {
    props: {
        size: {
            type: String,
            required: false,
        },
        buttonClass: {
            type: String,
            required: false,
        },
        type: {
            type: String,
            default: "button",
        },
        icon: {
            type: String,
            required: false,
            default: "",
        },
        variant: {
            type: String,
        },
    },
    methods: {
        onClick() {
            this.$emit("clicked");
        },
    },
};
</script>

<style scoped>
.base-button {
    border-style: solid;
    border-image: initial;
    font: inherit;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    border-radius: 0.375rem;
    border-width: 0;
    border-color: transparent;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15),
        0 1px 1px rgba(0, 0, 0, 0.075);
    margin: 0 0.25rem;
    padding: 0.25em 1em;
    display: inline-block;
    background: var(--btn-primary);
    color: var(--btn-primary-color);
}
.base-button:hover {
    background: var(--btn-primary-hover);
    border-color: var(--btn-primary-hover-border);
    border-width: 0;
}

.base-button:active {
    background: var(--btn-primary-active);
    border-color: var(--btn-primary-active-border);
}

.success {
    background: var(--btn-success);
}

.success:hover {
    background: var(--btn-success-hover);
    border-color: var(--btn-success-hover-border);
}

.success:active {
    background: var(--btn-success-active);
    border-color: var(--btn-success-active-border);
}

.lg {
    padding: 0.5rem 1.25rem;
    font-size: 1.25rem;
}
.xl {
    padding: 0.75rem 1.5rem;
    font-size: 1.5rem;
}
</style>