<template>
    <header
        class="
            default-header
            d-flex
            justify-content-between
            align-items-center
            py-xl-2
        "
    >
        <div>
            <a href="/" class="text-decoration-none" v-if="logoImage">
                <img :src="logoImage" alt="Logo" height="40" />
            </a>
        </div>
        <div class="d-flex" v-if="isQuiz">
            <div class="d-flex flex-column justify-content-center">
                <div class="d-flex">
                    <label class="fs-4 fw-bold me-2" data-test="time-counter">{{ time }}</label>
                    <base-icon
                        icon="bi bi-alarm"
                        size="1.5rem"
                        title="delay"
                    ></base-icon>
                </div>
            </div>
        </div>
    </header>
</template>

<script>
import { mapGetters } from "vuex";
import $DateTimeHelper from "@/helpers/DateTimeHelper";

export default {
    name: "NavBar",
    data() {
        return {
            time: "",
            $DateTimeHelper,
        };
    },
    computed: {
        ...mapGetters("settings", ["logoImage", "logoUrl"]),
        ...mapGetters("session", ["user", "startTime"]),

        isQuiz() {
            return this.$route?.path == "/quiz";
        },
    },

    updated() {
        if (this.isQuiz) {
            setInterval(() => {
                this.time = $DateTimeHelper.timeDifference(
                    this.$moment(),
                    this.startTime
                );
            }, 1000);
        }
    },
};
</script>
<style scoped>
.default-header {
    border-bottom: 2px solid var(--primary);
}
</style>
