import Vue from "vue"
import axios from "axios"
import esettings from "vue-esettings"

axios
    .get(window.baseURL + "settings.json")
    .then((response) => {
        Vue.use(esettings, { settings: response.data })
    })
    .catch((e) => {
        alert("could not load settings.json - " + e.response.message)
    })
