import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import axios from "axios"
import VueAxios from "vue-axios"
import VeeValidate from 'vee-validate';

import BaseButton from './components/ui/BaseButton'
import BaseIcon from './components/ui/BaseIcon'
import BaseToast from './components/ui/BaseToast'

import "./plugins/vue-ei18n"
import "./plugins/bootstrap"
import "./plugins/vue-esettings"
import "./plugins/sanitize"
import I18nFormatter from "./helpers/i18nHelper"

import moment from 'moment'

moment.locale(window.navigator.language || 'de')
Vue.prototype.$moment = moment

Vue.use(I18nFormatter)
Vue.use(VueAxios, axios)
Vue.use(VeeValidate, {
  inject: true,
  fieldsBagName: "veeFields",
  errorBagName: "veeErrors"
});

Vue.config.productionTip = false
Vue.component('base-icon', BaseIcon);
Vue.component('base-button', BaseButton);
Vue.component('base-toast', BaseToast);

const app = new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

if (window.Cypress) {
  window.app = app
}

