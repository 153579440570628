<template>
  <span>
    <i
      :class="[icon]"
      @click="onClick"
      :id="id"
      :title="tooltip"
      :style="`font-size: ${size}; color: ${color}`"
    ></i>
  </span>
</template>
<script>
export default {
  name: "BaseIcon",
  props: {
    icon: {
      type: String,
      required: false,
    },
    size: {
      type: String,
      default: "",
    },
    color: {
      type: String,
      required: false,
    },

    tooltip: {
      type: String,
      default: null,
    },

    id: {
      type: String,
      default: null,
    },
  },
  methods: {
    onClick() {
      this.$emit("click");
    },
  },
  computed: {
    hasToolTip() {
      return this.tooltip && this.id;
    },
    textColor() {
      return "text-" + this.type;
    },
  },
};
</script>