import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'

Vue.use(VueRouter)

const routes = [
  {
    // valid paths
    // http://localhost:8080/ 
    // http://localhost:8080/en 
    // http://localhost:8080/en/e63b3e84-3792-4321-b2a4-110484934e4a 
    path: '/:lang(de|en)?/:campaignId([0-9a-fA-F]{8}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{12})?',
    name: 'landingpage',
    component: () => import('../views/LandingPage.vue'),
    beforeEnter: (to, from, next) => {
      console.log(to)
      window.i18n.locale = to.params.lang || 'de'
      localStorage.setItem('Language', to.params.lang || 'de')
      localStorage.setItem('FinishHook', to.params.campaignId)
      next()
    }
  },
  {
    path: '/notice',
    name: 'NoticePage',
    component: () => import('../views/NoticePage.vue')
  },
  {
    path: '/quiz',
    name: 'QuizPage',
    component: () => import('../views/QuizPage.vue')
  },
  {
    path: '/result',
    name: 'ResultPage',
    component: () => import('../views/ResultPage.vue')
  },
  {
    path: '*',
    redirect: '/'
  }

]



const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  // redirect to landing page on refresh.
  if (to.name != "landingpage" && !store.state.session.user) {
    next({ name: 'landingpage' })
  }
  next()
})


export default router
