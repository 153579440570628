<template>
    <div id="app">
        <div class="container">
            <the-default-header></the-default-header>
            <main class="d-flex flex-grow-1 justify-content-center">
                <router-view />
            </main>
        </div>
    </div>
</template>
<script>
import TheDefaultHeader from "@/components/ui/TheDefaultHeader";
import { mapGetters } from "vuex";
export default {
    components: { TheDefaultHeader },

    name: "App",

    computed: {
        ...mapGetters("settings", ["Language"]),
    },

    async mounted() {
        await this.$store.dispatch("settings/settings");
        const lang = localStorage.getItem('Language') == null ? this.Language : window.i18n.locale
        window.i18n.locale = lang 
        this.$moment.locale(lang);
        document.title = this.$et("application", "websitetitle");
    },
};
</script>

