
import moment from "moment"

export default {
    namespaced: true,
    // -----------------------------------------------------------------
    state: {
        startTime: undefined,
        endTime: undefined,
        user: {
            Score: 0,
            Name: 'Muster',
            Email: 'M.Muster@MusterDomain.de',
            Gender: 'Other'
        },
        templates: undefined
    },
    // -----------------------------------------------------------------
    getters: {
        startTime: state => state.startTime ? state.startTime : undefined,
        endTime: state => state.endTime ? state.endTime : undefined,
        user: state => state.user ? state.user : undefined,
        templates: state => state.templates ? state.templates : undefined,
    },
    // -----------------------------------------------------------------
    mutations: {
        setStartTime: (state, data) => { state.startTime = data; },
        setEndTime: (state, data) => { state.endTime = data; },
        setUser: (state, user) => {
            state.user = {
                Score: user.Score || 0,
                Name: user.Name || "Muster",
                Email: user.Email || "M.Muster@MusterDomain.de",
                Gender: user.Gender || "Other"
            }
        },
        setTemplates: (state, data) => { state.templates = data; },
    },
    // -----------------------------------------------------------------
    actions: {
        setUserDetails: (context, user) => {
            context.commit("setUser", user);
        },

        startSession: (context) => {
            context.commit("setStartTime", moment(new Date()));
        },

        endSession: (context) => {
            context.commit("setEndTime", moment(new Date()));
        },

        setAnswers: (context, templates) => {
            context.commit("setTemplates", templates);
        },
    }
};
