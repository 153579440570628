<template>
    <div ref="el" class="toast p-1" data-test="toast">
        <div class="toast-body fw-bold fs-5 text-white d-flex justify-content-between"
         data-test="toast">{{settings.message}}</div>
    </div>
</template>

<script>
import { Toast } from "bootstrap";

export default {
    props: {
        settings: {
            type: Object,
            default: () => ({}),
        },
    },
    watch: {
        settings(newValue) {
            // set delay
            this.toast._config.delay = newValue.delay;

            // remove earlier set toast variant
            this.toast._element.classList.remove("bg-success");
            this.toast._element.classList.remove("bg-danger");

            // add new variant
            this.toast._element.classList.add(`bg-${newValue.variant}`);

            // show toast
            this.toast.show();
        },
    },
    name: "BootstrapToast",
    data() {
        return {
            toast: null,
        };
    },
    mounted() {
        this.toast = new Toast(this.$refs.el);
    },
};
</script>