import moment from "moment"

class DateTimeHelper {

    timeDifference(startTime, endTime) {
        let duration = moment.duration(startTime.diff(endTime));
        let sec = duration.asSeconds();
        var hour = Math.floor(sec / 3600);
        var minute = Math.floor((sec - hour * 3600) / 60);
        var updSecond = Math.floor(sec - (hour * 3600 + minute * 60));
        return this.formatDateTime(hour, minute, updSecond);
    }

    formatDateTime(hour, minute, second) {
        let time = second;

        if (minute) {
            time = `${this.appendZero(minute)}:${this.appendZero(time)}`;
        }

        if (hour) {
            time = `${this.appendZero(hour)}:${this.appendZero(time)}`;
        }
        return this.appendZero(time)
    }

    appendZero(t) {
        return t <= 9 ? "0" + t : t
    }
}

export default new DateTimeHelper()
